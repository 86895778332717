'use client';

import usePromotionCard from './hooks/usePromotionCard';
import Link from '@/client/components/Link';
import classNames from '@/external/classNames';
import Img from '@/shared/components/Img';
import { PromotionFragment } from '@/shared/graphql/PromotionFragment.generated';

type Props = {
  promotion: PromotionFragment;
  past?: boolean;
  showSummaryOnMobile?: boolean;
  position?: number;
  isMobile?: boolean;
  translations: Record<string, string>;
};

const PromotionCard: FC<Props> = (props) => {
  const { promotion, past, imgWidth, onClick, targetRef } =
    usePromotionCard(props);
  const { id, handle = '', largeImage, smallImage, link } = promotion;
  const { children } = props;

  return (
    <Link
      onClick={onClick}
      href={link || `/promotions/${handle}`}
      data-testid="promotion-card"
      key={id}
    >
      <div
        ref={targetRef}
        className={classNames(
          'bg-gohan md:bg-[unset] rounded-xl md:rounded-none block md:grid md:grid-cols-[minmax(12.5rem,43.75rem)_18.75rem] md:gap-10',
          'pt-2 px-4 pb-6 md:p-0',
        )}
      >
        <Img
          src={largeImage || smallImage || ''}
          className="block rounded-xl"
          height={290}
          width={imgWidth}
          mono={past ? '868383' : undefined}
          alt={promotion.name ?? ''}
        />
        <div>
          <div className="grid gap-4 auto-rows-max pt-4">
            <div className="grid gap-4 auto-rows-max px-2 md:p-0">
              <h3 className="text-moon-16 md:text-moon-24 font-medium text-bulma">
                {promotion.name}
              </h3>
              <p className="text-moon-16">{promotion.summary}</p>
            </div>
            {children}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PromotionCard;
