import { useCallback } from 'react';
import usePromoImpression from './usePromoImpression';
import sendPromotionClickToGtm from '../helpers/sendPromotionClickToGtm';
import { PromotionFragment } from '@/shared/graphql/PromotionFragment.generated';
type Props = {
  promotion: PromotionFragment;
  past?: boolean;
  showSummaryOnMobile?: boolean;
  position?: number;
  isMobile?: boolean;
};

const usePromotionCard = ({ promotion, past, position, isMobile }: Props) => {
  const { id, handle = '' } = promotion;
  const imgWidth = isMobile ? 343 : 700;
  const targetRef = usePromoImpression({
    handle,
    position: position?.toString(),
  });

  const onClick = useCallback(() => {
    sendPromotionClickToGtm({
      handle,
      position: `${past ? 'old-' : ''}promotions-list-${id}`,
    });
  }, [handle, id, past]);
  return { promotion, past, imgWidth, onClick, targetRef };
};

export default usePromotionCard;
